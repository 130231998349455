import React from "react"
import Layout from "../components/layout";
import { Helmet } from "react-helmet";    
import { Router } from "@reach/router"
 
const PlatbaPage = ({ pageContext, location }) => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Platba | Plyšovník.cz</title> 
    </Helmet>
    <Layout location={location}>
      
      
      <div className="relative px-4 sm:px-6 lg:px-8 lg:py-10 lg:pb-20 pb-20">
        
        <iframe id="content" title="content"></iframe>
        
      </div>
        
    </Layout>
  </>
)
 
export default PlatbaPage